import { getAppStatus, MOUNTED, registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
} from "single-spa-layout";
import Routes from "./routes";
import loadingGif from "./loading.gif";

(async function startApp() {
  const loadingScreen = document.createElement("div");
  loadingScreen.id = "loading-screen";
  loadingScreen.style.position = "fixed";
  loadingScreen.style.top = "0";
  loadingScreen.style.left = "0";
  loadingScreen.style.width = "100%";
  loadingScreen.style.height = "100%";
  loadingScreen.style.background = "rgba(255, 255, 255, 1)";
  loadingScreen.style.zIndex = "1000";
  loadingScreen.innerHTML = `
  <div class="loading-message" style="width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;">
    <img src=${loadingGif} alt="Javier Cardona">
  </div>
`;
  document.body.appendChild(loadingScreen);

  const routes = await Routes;
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });

  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach(registerApplication);
  layoutEngine.activate();

  start();

  const appsMounted = new Promise((resolve) => {
    const expectedApps = applications.filter((app) => {
      const initialStatus = getAppStatus(app.name);
      return initialStatus !== "NOT_LOADED"; // Solo considerar los que deberían montarse
    });

    const checkIfMounted = () => {
      if (
        expectedApps
          .filter((app) => getAppStatus(app.name) !== "SKIP_BECAUSE_BROKEN")
          .every((app) => getAppStatus(app.name) === MOUNTED)
      ) {
        resolve(true);
        window.removeEventListener("single-spa:routing-event", checkIfMounted);
      }
    };

    window.addEventListener("single-spa:routing-event", checkIfMounted);
    checkIfMounted();
  });

  const waitForImages = new Promise((resolve) => {
    const images = Array.from(document.querySelectorAll("img")).filter(
      (img) => {
        const loadingSrc = loadingGif;
        return img.src !== loadingSrc && !img.src.includes("loading.gif");
      }
    );

    let loadedCount = 0;

    if (images.length === 0) {
      resolve(true);
      return;
    }

    images.forEach((img) => {
      if (img.complete) {
        loadedCount++;
      } else {
        img.addEventListener("load", () => {
          loadedCount++;
          if (loadedCount === images.length) resolve(true);
        });
        img.addEventListener("error", () => {
          loadedCount++;
          if (loadedCount === images.length) resolve(true);
        });
      }
    });
  });

  const resourcesLoaded = Promise.all([
    new Promise((resolve) => {
      if (document.readyState === "complete") {
        resolve(true);
      } else {
        window.addEventListener("load", () => resolve(true), { once: true });
      }
    }),
    waitForImages,
  ]);

  Promise.all([appsMounted, resourcesLoaded]).then(() => {
    loadingScreen.style.display = "none";
  });
})();
