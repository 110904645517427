import { constructRoutes } from "single-spa-layout";

const Routes = constructRoutes({
  routes: [
    {
      type: "route",
      path: "/",
      routes: [
        {
          type: "application",
          name: "@jc-portfolio/portfolio-header",
          props: {
            some: "header",
            menu: [
              {
                title: "Inicio",
                icon: "",
                route: "/",
                order: 1,
                login: false,
              },
              {
                title: "Proyectos",
                icon: "",
                route: "/angular",
                order: 3,
                login: false,
              },
              {
                title: "Sobre mi",
                icon: "",
                route: "/react",
                order: 2,
                login: false,
              },
            ],
            menuAccount: [
              {
                title: "Registro",
                icon: "",
                route: "/registro",
                order: 1,
                login: false,
              },
              {
                title: "Login",
                icon: "",
                route: "/login",
                order: 2,
                login: false,
              },
              {
                title: "Cuenta",
                icon: "user",
                route: "/account",
                order: 3,
                login: true,
              },
            ],
          },
        },
      ],
    },
    {
      type: "route",
      path: "/",
      exact: true,
      routes: [
        {
          type: "application",
          name: "@jc-portfolio/portfolio-home",
          props: {
            some: "home",
          },
        },
      ],
    },
    {
      type: "route",
      path: "/angular",
      routes: [
        {
          type: "application",
          name: "@jc-portfolio/portfolio-client",
          props: {
            some: "angular",
          },
        },
      ],
    },
    {
      type: "route",
      path: "/react",
      routes: [
        {
          type: "application",
          name: "@jc-portfolio/portfolio-client-react",
          props: {
            some: "react",
          },
        },
      ],
    },
    {
      type: "route",
      path: "/",
      routes: [
        {
          type: "application",
          name: "@jc-portfolio/portfolio-footer",
          props: {
            some: "footer",
          },
        },
      ],
    },
  ],
});

export default Routes;
