import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
} from "single-spa-layout";
// import microfrontendLayout from "./microfrontend-layout.html";
import Routes from "./routes";

// const routes = constructRoutes(microfrontendLayout);

// registerApplication({
//   name: "@jc-portfolio/portfolio-header",
//   app: () => System.import<LifeCycles<{}>>("@jc-portfolio/portfolio-header"),
//   activeWhen: ["/"],
//   customProps: {
//     some: "header",
//   },
// });
//
// registerApplication({
//   name: "@jc-portfolio/portfolio-home",
//   app: () => System.import<LifeCycles<{}>>("@jc-portfolio/portfolio-home"),
//   activeWhen: ["/"],
//   customProps: {
//     some: "home",
//   },
// });
//
// registerApplication({
//   name: "@jc-portfolio/portfolio-client",
//   app: () => System.import<LifeCycles<{}>>("@jc-portfolio/portfolio-client"),
//   activeWhen: ["/client"],
//   customProps: {
//     some: "client",
//   },
// });
//
// registerApplication({
//   name: "@jc-portfolio/portfolio-client-react",
//   app: () =>
//     System.import<LifeCycles<{}>>("@jc-portfolio/portfolio-client-react"),
//   activeWhen: ["/client-react"],
//   customProps: {
//     some: "client-react",
//   },
// });
//
// registerApplication({
//   name: "@jc-portfolio/portfolio-footer",
//   app: () => System.import<LifeCycles<{}>>("@jc-portfolio/portfolio-footer"),
//   activeWhen: ["/"],
//   customProps: {
//     some: "footer",
//   },
// });

const routes = Routes;

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

start();
