import { constructRoutes } from "single-spa-layout";
import { getAppStatus } from "single-spa";

declare const __webpack_public_path__: string;
declare const __webpack_init_sharing__: any;
declare const __webpack_share_scopes__: any;

declare const __IS_LOCAL__: boolean;

const localUrls = {
  "@jc-portfolio/portfolio-header": "//localhost:4201/main.js",
  "@jc-portfolio/portfolio-home": "//localhost:4200/main.js",
  "@jc-portfolio/portfolio-client": "//localhost:4300/main.js",
  "@jc-portfolio/portfolio-auth": "//localhost:8084/main.js",
  "@jc-portfolio/portfolio-footer": "//localhost:4204/main.js",
};

const productionUrls = {
  "@jc-portfolio/portfolio-client":
    "https://web.javier-cardona.com/angular/main.js",
  "@jc-portfolio/portfolio-header":
    "https://web.javier-cardona.com/header/main.js",
  "@jc-portfolio/portfolio-home": "https://web.javier-cardona.com/home/main.js",
  "@jc-portfolio/portfolio-footer":
    "https://web.javier-cardona.com/footer/main.js",
  "@jc-portfolio/portfolio-auth": "https://web.javier-cardona.com/auth/main.js",
};

const microfrontendUrls = __IS_LOCAL__ ? localUrls : productionUrls;

const appsMounted = new Promise((resolve) => {
  const expectedApps = [
    "@jc-portfolio/portfolio-header",
    "@jc-portfolio/portfolio-home",
    "@jc-portfolio/portfolio-client",
    "@jc-portfolio/portfolio-footer",
    "@jc-portfolio/portfolio-auth",
  ].filter((app) => {
    const initialStatus = getAppStatus(app);
    return initialStatus !== "NOT_LOADED";
  });

  const checkIfMounted = () => {
    if (
      expectedApps
        .filter((app) => getAppStatus(app) !== "SKIP_BECAUSE_BROKEN")
        .every((app) => getAppStatus(app) === "MOUNTED")
    ) {
      resolve(true);
      window.removeEventListener("single-spa:routing-event", checkIfMounted);
    }
  };

  window.addEventListener("single-spa:routing-event", checkIfMounted);
  checkIfMounted();
});

async function checkMicrofrontendAvailability(url: string): Promise<boolean> {
  try {
    const response = await fetch(url, { method: "HEAD" });
    return response.ok;
  } catch (error) {
    return false;
  }
}

async function getRoutes() {
  const routes = [
    {
      type: "route",
      path: "/",
      exact: true,
      routes: [],
    },
    {
      type: "route",
      path: "/angular",
      exact: true,
      routes: [],
    },
    {
      type: "route",
      path: "/react",
      exact: true,
      routes: [],
    },
    {
      type: "route",
      path: "/auth",
      exact: true,
      routes: [],
    },
  ];

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-header"]
    )
  ) {
    routes.forEach((route) => {
      route.routes.push({
        type: "application",
        name: "@jc-portfolio/portfolio-header",
        props: {
          menu: [
            {
              title: "About me",
              icon: undefined,
              route: undefined,
              order: 2,
              login: false,
              btn: false,
              type: undefined,
            },
            {
              title: "Skills",
              icon: undefined,
              route: undefined,
              order: 3,
              login: false,
              btn: false,
              type: undefined,
            },
            {
              title: "Projects",
              icon: undefined,
              route: undefined,
              order: 4,
              login: false,
              btn: false,
              type: undefined,
            },
            {
              title: "Contact",
              icon: undefined,
              route: undefined,
              order: 4,
              login: false,
              btn: false,
              type: undefined,
            },
          ],
          menuAccount: [
            {
              title: "Sign in",
              icon: undefined,
              route: "/auth",
              order: 1,
              login: false,
              btn: false,
              type: undefined,
            },
            {
              title: "Sign up",
              icon: "user",
              route: "/auth",
              order: 2,
              login: false,
              btn: true,
              type: "primary",
            },
            {
              title: "New",
              icon: "plus",
              route: "",
              order: 1,
              login: true,
              btn: true,
              type: "secondary",
            },
            {
              title: "Account",
              icon: "user",
              route: "/dashboard",
              order: 2,
              login: true,
              btn: true,
              type: "primary",
            },
          ],
        },
      });
    });
  }

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-home"]
    )
  ) {
    routes
      .find((r) => r.path === "/")
      .routes.push({
        type: "application",
        name: "@jc-portfolio/portfolio-home",
        loader: "loading home...",
        props: {
          some: "home",
        },
      });
  }

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-client"]
    )
  ) {
    routes[1].routes.push({
      type: "application",
      name: "@jc-portfolio/portfolio-client",
      props: {
        some: "angular",
      },
    });
  }

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-client-react"]
    )
  ) {
    routes[2].routes.push({
      type: "application",
      name: "@jc-portfolio/portfolio-client-react",
      props: {
        some: "react",
      },
    });
  }

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-auth"]
    )
  ) {
    routes
      .find((r) => r.path === "/auth")
      .routes.push({
        type: "application",
        name: "@jc-portfolio/portfolio-auth",
        props: {},
      });
  }

  if (
    await checkMicrofrontendAvailability(
      microfrontendUrls["@jc-portfolio/portfolio-footer"]
    )
  ) {
    routes.forEach((route) => {
      route.routes.push({
        type: "application",
        name: "@jc-portfolio/portfolio-footer",
        props: {
          socialMedia: [
            {
              icon: "facebook",
              url: "https://www.facebook.com/javier.cardona.79025648/",
            },
            {
              icon: "instagram",
              url: "https://www.instagram.com/javier.cardona.69/",
            },
            {
              icon: "github",
              url: "https://github.com/JavierCardonadev",
            },
            {
              icon: "linkedin",
              url: "https://www.linkedin.com/in/cardona-javier",
            },
          ],
        },
      });
    });
  }

  return routes;
}

export default (async () => {
  const dynamicRoutes = await getRoutes();
  return constructRoutes({ routes: dynamicRoutes });
})();
